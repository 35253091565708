import React, { useState, useEffect, useCallback } from "react";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../colors";
import { AdminManager, AdminTable, Settings, Messages } from "./";
import logo from "../../assets/brewsaver-logo-bubbles.png";
import { AppMain, AppWrapper, navHeight } from "../shared";
import { MdMenu } from "@react-icons/all-files/md/MdMenu";
import { MdClose } from "@react-icons/all-files/md/MdClose";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { getCookie, handleLogout } from "../../utility";
import { ToastContainer } from "react-toastify";
import { Loader } from "../loaders";
import { Copyright } from "../copyright";

export const GET_USER_FROM_COOKIE = gql`
  query ($cookie: String!) {
    userFromCookie(cookie: $cookie) {
      address
      city
      email
      firstName
      lastName
      notes
      phone
      state
      userType
      userStatus
      zipCode
    }
  }
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  background-color: ${colors.secondary};
  color: ${colors.secondaryOffset};
  height: ${navHeight};
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  max-width: 1850px;
  margin: auto;
  z-index: 10;
`;

const NavMenuOpener = styled(MdMenu)`
  padding: 10px;
  color: ${colors.defaultBg};
  display: none;
  max-width: 100%;

  @media (max-width: 650px) {
    display: flex;
  }
`;

const NavMenuCloser = styled(MdClose)`
  padding: 10px;
  color: ${colors.defaultBg};
  display: none;
  max-width: 100%;

  @media (max-width: 650px) {
    display: flex;
  }
`;

const NavMenu = styled.menu`
  margin: 0;
  padding: 0;
  width: ${({ width }) => width}px;
  transition: all 1s;
  height: calc(100% - ${navHeight});
  background-color: ${colors.secondary};
  position: absolute;
  right: 0;
  z-index: 10;
  list-style: none;
  overflow-x: hidden;
  max-width: 100%;
`;

const NavMenuHeader = styled.h2`
  color: ${colors.secondaryOffset};
  padding: 7.5px 20px;
  margin: 0;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  border-top: 1px solid ${colors.secondaryOffset};
  border-bottom: 1px solid ${colors.secondaryOffset};
`;

const NavMenuItem = styled.li`
  padding: 7.5px 10px;
`;

const Logo = styled.img`
  padding: 2.5px 10px;
  height: 65px;
`;

const List = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;

  @media (max-width: 650px) {
    display: none;
  }
`;

const ListItem = styled.li``;

const StyledLink = styled(Link)`
  padding: 5px 10px;
  color: ${colors.secondaryOffset};
  text-decoration: none;
  font-weight: 500;
  letter-spacing: 0.5px;
  transition: font-size 0.5s;
  flex-wrap: wrap;

  &:hover {
    font-size: 1.1rem;
    text-decoration: underline;
  }
`;

const RightNavWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;

  @media (max-width: 650px) {
    display: none;
  }
`;

const AdminLanding = ({ match }) => {
  const { loading, error, data } = useQuery(GET_USER_FROM_COOKIE, {
    variables: { cookie: getCookie("uuid") },
    fetchPolicy: "no-cache"
  });
  const [authenticated, setAuth] = useState(false);
  let { path, url } = useRouteMatch();
  const [navLinks, setNavLinks] = useState([
    <StyledLink to={`${url}/table/member`}>Members</StyledLink>,
    <StyledLink to={`${url}/table/client`}>Clients</StyledLink>,
    <StyledLink to={`${url}/table/venue`}>Venues</StyledLink>,
    <StyledLink to={`${url}/table/offer`}>Offers</StyledLink>,
    <StyledLink to={`${url}/table/redemption`}>Redemptions</StyledLink>,
    <StyledLink to={`${url}/table/accessCode`}>Access Codes</StyledLink>,
    <StyledLink to={`${url}/settings`}>Notifications</StyledLink>,
    <StyledLink to={`${url}/messages`}>Messages</StyledLink>
  ]);
  const [navMenuWidth, setNavMenuWidth] = useState(0);

  const resizeEvent = useCallback(() => {
    if (window.innerWidth > 650 && navMenuWidth !== 0) {
      setNavMenuWidth(0);
    }
  }, [window.innerWidth, navMenuWidth]);

  useEffect(() => {
    window.addEventListener("resize", resizeEvent);
    return () => window.removeEventListener("resize", resizeEvent);
  }, [resizeEvent]);

  useEffect(() => {
    setNavLinks([
      <StyledLink to={`${url}/table/member`}>Members</StyledLink>,
      <StyledLink to={`${url}/table/client`}>Clients</StyledLink>,
      <StyledLink to={`${url}/table/venue`}>Venues</StyledLink>,
      <StyledLink to={`${url}/table/offer`}>Offers</StyledLink>,
      <StyledLink to={`${url}/table/redemption`}>Redemptions</StyledLink>,
      <StyledLink to={`${url}/table/accessCode`}>Access Codes</StyledLink>,
      <StyledLink to={`${url}/settings`}>Notifications</StyledLink>,
      <StyledLink to={`${url}/messages`}>Messages</StyledLink>
    ]);
  }, [url]);

  useEffect(() => {
    if (data) {
      if (data.userFromCookie?.userType === "admin") {
        setAuth(true);
      }
    }
  }, [data, setAuth]);

  const toggleMenu = useCallback(() => {
    if (navMenuWidth === 0) setNavMenuWidth(400);
    else setNavMenuWidth(0);
  }, [navMenuWidth, setNavMenuWidth]);

  if (error) {
    return <div>{error.message}</div>;
  }

  if (loading) {
    return <Loader />;
  }

  if (data && !authenticated) {
    return (
      <div>
        not authenticated
        <br />
        <a href="/">login here</a>
      </div>
    );
  }

  const memberTable = () => {
    return <AdminTable tableRoute="member" />;
  };

  return (
    <AppWrapper>
      <AppMain>
        <Nav>
          <Logo src={logo} />
          <List>
            {navLinks.map((link) => (
              <ListItem>{link}</ListItem>
            ))}
          </List>
          <RightNavWrapper>
            {/* <StyledLink to={`${path}/settings`}>
              <IoMdSettings size={"20px"} />
            </StyledLink> */}
            <StyledLink onClick={handleLogout} to={"/"}>
              Logout
            </StyledLink>
          </RightNavWrapper>
          {navMenuWidth === 0 && <NavMenuOpener onClick={toggleMenu} size={"50px"} />}
          {navMenuWidth > 0 && <NavMenuCloser onClick={toggleMenu} size={"50px"} />}
        </Nav>
        <NavMenu width={navMenuWidth}>
          {navMenuWidth > 0 && (
            <>
              <NavMenuHeader>
                Welcome {data.userFromCookie.firstName} {data.userFromCookie.lastName}
              </NavMenuHeader>
              {navLinks.map((link) => (
                <NavMenuItem onClick={toggleMenu}>{link}</NavMenuItem>
              ))}
              <NavMenuItem onClick={toggleMenu}>
                <StyledLink onClick={handleLogout} to={"/"}>
                  Logout
                </StyledLink>
              </NavMenuItem>
            </>
          )}
        </NavMenu>
        <Switch>
          <Route path={`${path}/table/:table/:filterName?/:filterValue?`} component={AdminTable} />
          <Route path={`${path}/manage/:entity/:identifier?`} component={AdminManager} />
          <Route path={`${path}/settings`} component={Settings} />
          <Route path={`${path}/messages`} component={Messages} />
          <Route default render={memberTable} />
        </Switch>
        <Copyright color="black" />
      </AppMain>
      <ToastContainer autoClose={2000} />
    </AppWrapper>
  );
};
export default AdminLanding;
