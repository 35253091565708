import React from "react";
import { useParams } from "react-router-dom";
import { ClientManager, MemberManager, OfferManager, VenueManager, AccessCodeManager } from "../manager";

export const AdminManager = () => {
  let { entity, identifier } = useParams();
  switch (entity) {
    case "member":
      return <MemberManager identifier={identifier} />;
    case "client":
      return <ClientManager identifier={identifier} />;
    case "venue":
      return <VenueManager identifier={identifier} />;
    case "offer":
      return <OfferManager identifier={identifier} />;
    case "accessCode":
      return <AccessCodeManager identifier={identifier} />;
    default:
      return <div>404 url not found</div>;
  }
};
