import React, { useReducer, useCallback } from "react";
import { MdFileUpload } from "@react-icons/all-files/md/MdFileUpload";
import { TableButton } from ".";
import { FileUpload } from "../inputs";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { toast } from "react-toastify";

const UPLOAD_CODES = gql`
  mutation ($codes: [String]!, $expiredDate: String!) {
    uploadAccessCode(codes: $codes, expiredDate: $expiredDate) {
      success
    }
  }
`;

const defaultState = {
  inputId: "hidden_upload_file_input",
  isSubmitting: false,
  dirtyFields: [],
  isModalVisible: false
};

const importDispatch = (state, action) => {
  switch (action.type) {
    case "SET_INPUT_ID": {
      const newState = { ...state };
      newState.inputId = action.payload;
      return newState;
    }
    case "SHOW_MODAL": {
      return {
        ...state,
        isModalVisible: action.payload
      };
    }
    case "START_SAVE": {
      return {
        ...state,
        isSubmitting: true
      };
    }
    case "STOP_SAVE": {
      return {
        ...state,
        isSubmitting: false,
        dirtyFields: []
      };
    }
    default:
      return { ...state };
  }
};

const buttonSize = "2em";
export const UploadAccessCodesButton = ({ table, onUploadFinish }) => {
  const [uploadCodes] = useMutation(UPLOAD_CODES);
  const [{ inputId }, componentDispatch] = useReducer(importDispatch, Object.assign({}, defaultState));

  const openFileDialog = useCallback(() => {
    document.getElementById(inputId).click();
  }, [inputId]);

  const handleFileUpload = useCallback(
    (file) => {
      const lines = file.split("\n");
      const delimiter = ",";
      let codes = [];
      lines.forEach((line) => {
        codes = codes.concat(line.split(delimiter));
      });
      const makeRequest = async (codes) => {
        const nextYear = new Date();
        nextYear.setFullYear(nextYear.getFullYear() + 1);
        const variables = { codes, expiredDate: nextYear };
        const ok = await uploadCodes({
          variables
        });
        if (ok) {
          toast.success(`Uploaded codes successfully.`, {
            position: "bottom-center",
            hideProgressBar: true,
            pauseOnHover: false,
            closeButton: false
          });
        }
        if (onUploadFinish) {
          onUploadFinish();
        }
      };
      makeRequest(codes);
    },
    [uploadCodes, onUploadFinish]
  );

  return (
    <>
      <TableButton
        key={`${table}_export`}
        icon={<MdFileUpload size={buttonSize} />}
        text="Upload Codes"
        handleClick={openFileDialog}
      >
        <FileUpload id={inputId} name="upload" onChange={handleFileUpload} />
      </TableButton>
    </>
  );
};
