import { useQuery } from "@apollo/react-hooks";
import React, { useEffect, useRef, useState, useCallback } from "react";
import styled from "styled-components";
import {
  TableButton,
  TableFilter,
  TableHeader,
  TableRows,
  FilterButton,
  ExportButton,
  ImportButton
} from ".";
import { colors } from "../../colors";
import { Loader } from "../loaders";
import { useTableViewModelContext } from "./tableContext";
import { UploadAccessCodesButton } from "./uploadAccessCodesButton";

const Section = styled.section`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 100%;
  padding: 5px 5px 0px 5px;
  height: 100%;
  box-sizing: border-box;
  overflow-x: auto;
`;

const MenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  position: sticky;
  left: 0;
  padding-bottom: 5px;
`;

const TableTitle = styled.h2`
  margin: 0;
  margin-left: 5px;
  padding: 0;
  text-transform: capitalize;
  font-size: 32px;
  color: ${colors.primary};
  text-shadow: 0px 1px black;
`;

const TableSubTitle = styled.span`
  font-size: 20px;
  color: ${colors.darkFont};
  text-shadow: none;
  font-weight: normal;
  padding-left: 25px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
`;
//${1800 / numColumns}px)
const Grid = styled.div`
  display: grid;
  grid-template-columns: ${({ areRowsSelectable, numColumns }) =>
    `${areRowsSelectable ? "40px " : ""}${`minmax(auto, auto) `.repeat(numColumns)}`};
  max-width: 100%;
  height: 100%;
  min-height: calc(100vh - 200px);
  margin-bottom: 10px;
  grid-template-rows: repeat(auto-fill, 40px);
  overflow-y: auto;
`;

export const Table = ({
  table,
  buttons,
  areRowsSelectable,
  isUploadVisible,
  isImportVisible,
  isExportVisible,
  isSelectVisible,
  filter
}) => {
  const sectionRef = useRef(null);
  const wrapperRef = useRef(null);
  const [tableData, tableDispatch] = useTableViewModelContext();
  const { loading, error, data, refetch } = useQuery(tableData[table].query, {
    variables: tableData[table].variables,
    fetchPolicy: "no-cache"
  });
  const [ids, setIds] = useState([]);

  const handleImportFinish = useCallback(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    tableDispatch({
      type: "SET_LOADING",
      payload: { table, isLoading: false }
    });
  }, [tableData[table].rows]);

  useEffect(() => {
    if (data) {
      const rows = data[tableData[table].accessor].edges;
      rows.forEach((row, idx) => (row.node.id = idx));
      tableDispatch({
        type: "SET_TABLE_ROWS",
        payload: {
          table,
          rows
        }
      });
      setIds(
        data[tableData[table].accessor].edges.map((edge) => {
          return edge.node[tableData[table].identifier];
        })
      );
    }
  }, [data, tableDispatch, setIds]);

  useEffect(() => {
    if (data && filter.name && filter.value) {
      const filters = { [filter.name]: filter.value };
      tableDispatch({
        type: "FILTER_DATA",
        payload: { table, filters }
      });
    }
  }, [filter, data, tableDispatch]);

  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <Section ref={sectionRef}>
      {(loading || tableData[table].isLoading) && <Loader />}
      <TableWrapper ref={wrapperRef}>
        <MenuWrapper>
          <TableTitle>
            {tableData[table].title || `${table} Table`}
            <TableSubTitle>{`${tableData[table]?.filtered.length} rows`}</TableSubTitle>
          </TableTitle>
          <ButtonWrapper key={`${table}_buttons`}>
            {buttons && buttons.map((button) => <TableButton key={`${table}_${button.text}`} {...button} />)}
            {isUploadVisible && <UploadAccessCodesButton table={table} onUploadFinish={handleImportFinish} />}
            <FilterButton table={table} />
            {isExportVisible && <ExportButton table={table} />}
            {isImportVisible && <ImportButton table={table} onImportFinish={handleImportFinish} />}
          </ButtonWrapper>
        </MenuWrapper>
        <Grid
          key={`${table}_grid`}
          areRowsSelectable={areRowsSelectable}
          numColumns={tableData[table].columns.length}
        >
          {data && (
            <>
              <TableHeader table={table} isSelectable={areRowsSelectable} ids={ids} />
              <TableFilter table={table} isSelectable={areRowsSelectable} ids={ids} filter={filter} />
              <TableRows table={table} areRowsSelectable={areRowsSelectable} />
            </>
          )}
        </Grid>
      </TableWrapper>
    </Section>
  );
};
