import React, { useCallback, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Table } from "../";
import { MdAdd } from "@react-icons/all-files/md/MdAdd";
import { MdAutorenew } from "@react-icons/all-files/md/MdAutorenew";
import { MdCheck } from "@react-icons/all-files/md/MdCheck";
import { MdClose } from "@react-icons/all-files/md/MdClose";
import { MdRemove } from "@react-icons/all-files/md/MdRemove";
import { useTableViewModelContext, useSelectViewModelContext } from "../table";
import { toast } from "react-toastify";

import gql from "graphql-tag";
import { Button, ConfirmText, Modal, ConfirmButtonWrapper, ModalWrapper } from "../shared";
import { useMutation } from "@apollo/react-hooks";

const CLEAR_REDEMPTIONS = gql`
  mutation ($ids: [ID]!, $item: String!) {
    clearRedemptions(ids: $ids, item: $item) {
      success
    }
  }
`;

const ACTIVATE_USERS = gql`
  mutation ($ids: [ID]!) {
    activateUsers(ids: $ids) {
      success
    }
  }
`;

const DEACTIVATE_USERS = gql`
  mutation ($ids: [ID]!) {
    deactivateUsers(ids: $ids) {
      success
    }
  }
`;

const DELETE_MUTATIONS = {
  member: gql`
    mutation ($ids: [ID]!) {
      deleteUsers(ids: $ids) {
        success
      }
    }
  `,
  client: gql`
    mutation ($ids: [ID]!) {
      deleteUsers(ids: $ids) {
        success
      }
    }
  `,
  venue: gql`
    mutation ($ids: [ID]!) {
      deleteVenues(ids: $ids) {
        success
      }
    }
  `,
  offer: gql`
    mutation ($ids: [ID]!) {
      deleteOffers(ids: $ids) {
        success
      }
    }
  `,
  redemption: gql`
    mutation ($ids: [ID]!) {
      deleteRedemptions(ids: $ids) {
        success
      }
    }
  `,
  accessCode: gql`
    mutation ($ids: [ID]!) {
      deleteAccessCodes(ids: $ids) {
        success
      }
    }
  `
};

const buttonSize = "2em";
export const AdminTable = ({ tableRoute }) => {
  let { table, filterName, filterValue } = useParams();
  if (tableRoute) {
    table = tableRoute; // probably shouldnt do this maybe use state
  }
  const [selectData, selectDispatch] = useSelectViewModelContext();
  const [tableData, tableDispatch] = useTableViewModelContext();
  const [deleteEntries] = useMutation(DELETE_MUTATIONS[table]);
  const [clearRedemptions] = useMutation(CLEAR_REDEMPTIONS);
  const [activateUsers] = useMutation(ACTIVATE_USERS);
  const [deactivateUsers] = useMutation(DEACTIVATE_USERS);
  const [isModalVisible, setModalVisible] = useState(false);
  const [filter, setFilter] = useState({ name: filterName, value: filterValue });

  useEffect(() => {
    setFilter({ name: filterName, value: filterValue });
  }, [filterName, filterValue]);

  const showModal = useCallback(() => {
    setModalVisible(true);
  }, [setModalVisible]);

  const hideModal = useCallback(() => {
    setModalVisible(false);
  }, [setModalVisible]);

  const handleDelete = useCallback(() => {
    const ids = selectData[table].filter((item) => item !== -1);
    deleteEntries({
      variables: {
        ids: ids
      }
    }).then(
      () => {
        toast.success(`Successfully deleted`, {
          position: "bottom-center",
          hideProgressBar: true,
          pauseOnHover: false,
          closeButton: false,
          autoClose: 2500
        });
      },
      () => {
        toast.error(`Something went wrong`, {
          position: "bottom-center",
          hideProgressBar: true,
          pauseOnHover: false,
          closeButton: false,
          autoClose: 2500
        });
      }
    );
    selectDispatch({
      type: "DELETE_SELECTED",
      payload: table
    });
    tableDispatch({
      type: "DELETE_IDS",
      payload: { table, ids }
    });
    setModalVisible(false);
  }, [selectData, tableData, table]);

  const handleClearRedemption = useCallback(() => {
    const ids = selectData[table].filter((item) => item !== -1);
    clearRedemptions({
      variables: {
        ids,
        item: table
      }
    });
    tableDispatch({
      type: "SET_IDS_COLUMN",
      payload: { table, ids, column: "redemptions", value: [] }
    });
  }, [selectData, table]);

  const handleActivateUser = useCallback(() => {
    const ids = selectData[table].filter((item) => item !== -1);
    activateUsers({
      variables: {
        ids
      }
    });
    tableDispatch({
      type: "SET_IDS_COLUMN",
      payload: { table, ids, column: "userStatus", value: "active" }
    });
  }, [selectData, table]);

  const handleDeactivateUser = useCallback(() => {
    const ids = selectData[table].filter((item) => item !== -1);
    deactivateUsers({
      variables: {
        ids
      }
    });
    tableDispatch({
      type: "SET_IDS_COLUMN",
      payload: { table, ids, column: "userStatus", value: "inactive" }
    });
  }, [selectData, table]);

  const buttons = {
    member: [
      {
        icon: <MdAdd size={buttonSize} />,
        text: "Add Member",
        link: "/admin/manage/member"
      },
      {
        icon: <MdRemove size={buttonSize} />,
        text: "Delete Member",
        handleClick: showModal
      },
      {
        icon: <MdCheck size={buttonSize} />,
        text: "Activate Member",
        handleClick: handleActivateUser
      },
      {
        icon: <MdClose size={buttonSize} />,
        text: "Deactivate Member",
        handleClick: handleDeactivateUser
      },
      {
        icon: <MdAutorenew size={buttonSize} />,
        text: "Clear Redemptions",
        handleClick: handleClearRedemption
      }
    ],
    client: [
      {
        icon: <MdAdd size={buttonSize} />,
        text: "Add Client",
        link: "/admin/manage/client"
      },
      {
        icon: <MdRemove size={buttonSize} />,
        text: "Delete Client",
        handleClick: showModal
      },
      {
        icon: <MdCheck size={buttonSize} />,
        text: "Activate Client",
        handleClick: handleActivateUser
      },
      {
        icon: <MdClose size={buttonSize} />,
        text: "Deactivate Client",
        handleClick: handleDeactivateUser
      }
    ],
    venue: [
      {
        icon: <MdAdd size={buttonSize} />,
        text: "Add Venue",
        link: "/admin/manage/venue"
      },
      {
        icon: <MdRemove size={buttonSize} />,
        text: "Delete Venue",
        handleClick: showModal
      },
      {
        icon: <MdAutorenew size={buttonSize} />,
        text: "Clear Redemptions",
        handleClick: handleClearRedemption
      }
    ],
    offer: [
      {
        icon: <MdAdd size={buttonSize} />,
        text: "Add Offer",
        link: "/admin/manage/offer"
      },
      {
        icon: <MdRemove size={buttonSize} />,
        text: "Delete Offer",
        handleClick: showModal
      },
      {
        icon: <MdAutorenew size={buttonSize} />,
        text: "Clear Redemptions",
        handleClick: handleClearRedemption
      }
    ],
    redemption: [
      {
        icon: <MdAutorenew size={buttonSize} />,
        text: "Clear Redemptions",
        handleClick: showModal
      }
    ],
    accessCode: [
      {
        icon: <MdAdd size={buttonSize} />,
        text: "Add Access Code",
        link: "/admin/manage/accessCode"
      },
      {
        icon: <MdRemove size={buttonSize} />,
        text: "Delete Access Code",
        handleClick: showModal
      }
    ]
  };

  return (
    <>
      <Table
        table={table}
        buttons={buttons[table]}
        areRowsSelectable
        isUploadVisible={table === "accessCode"}
        isImportVisible={table === "member" || table === "client"}
        isExportVisible={table === "member" || table === "client"}
        filter={filter}
      />
      {isModalVisible && (
        <ModalWrapper>
          <Modal>
            <ConfirmText>
              Really? Delete {selectData[table].filter((rowId) => rowId !== -1)?.length} Record(s)
            </ConfirmText>
            <ConfirmButtonWrapper>
              <Button onClick={handleDelete}>Yes, Delete</Button>
              <Button onClick={hideModal}>Cancel</Button>
            </ConfirmButtonWrapper>
          </Modal>
        </ModalWrapper>
      )}
    </>
  );
};
