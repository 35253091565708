import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import { MdAdd } from "@react-icons/all-files/md/MdAdd";
import { MdSave } from "@react-icons/all-files/md/MdSave";
import { MdRemove } from "@react-icons/all-files/md/MdRemove";
import { toast, ToastContainer } from "react-toastify";
import styled from "styled-components";
import { handleReject } from "../../utility";
import { Loader } from "../loaders";
import {
  Button,
  ConfirmButtonWrapper,
  ConfirmText,
  Form,
  iconSize,
  Label,
  ManagerButton,
  ManagerButtonText,
  Modal,
  ModalWrapper,
  StyledDatePicker,
  Text,
  TextInput
} from "../shared";
import { useSelectViewModelContext, useTableViewModelContext } from "../table";

const GET_ACCESS_CODE = gql`
  query ($accessCodeId: ID) {
    accessCode(accessCodeId: $accessCodeId) {
      accessCodeId
      code
      expiredDate
      userId
    }
  }
`;

const CREATE_ACCESS_CODE = gql`
  mutation ($code: String!, $expiredDate: String!) {
    createAccessCode(code: $code, expiredDate: $expiredDate) {
      accessCodeId
    }
  }
`;

const DELETE_ACCESS_CODES = gql`
  mutation ($ids: [ID]!) {
    deleteAcessCodes(ids: $ids) {
      success
    }
  }
`;

const UPDATE_ACCESS_CODE = gql`
  mutation ($changes: AccessCodeInput!, $accessCodeId: ID!) {
    updateAccessCode(changes: $changes, accessCodeId: $accessCodeId) {
      accessCode {
        accessCodeId
        code
        expiredDate
      }
    }
  }
`;

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`;

const LeftButtons = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Header = styled.h2`
  margin: 0;
  padding: 0;
`;

const RightButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const defaultState = {
  code: "",
  expiredDate: "",
  isSubmitting: false,
  dirtyFields: [],
  isModalVisible: false,
  isDeleteVisible: false
};

const memberManagerDispatch = (state, action) => {
  switch (action.type) {
    case "SET_IDENTIFIER": {
      return {
        ...state,
        identifier: action.payload
      };
    }
    case "SET_ALL": {
      return {
        ...action.payload
      };
    }
    case "SET_ONE": {
      const newState = { ...state };
      newState[action.payload.name] = action.payload.value;
      if (!newState.dirtyFields.includes(action.payload.name)) {
        newState.dirtyFields.push(action.payload.name);
      }
      return newState;
    }
    case "SET_DATE": {
      const newState = { ...state };
      if (action.payload.value === null) {
        newState[action.payload.name] = action.payload.value;
      } else {
        newState[action.payload.name] = new Date(action.payload.value);
      }
      if (
        !newState.dirtyFields.includes(action.payload.name) &&
        newState[action.payload.name] !== state[action.payload.name]
      ) {
        newState.dirtyFields.push(action.payload.name);
      }
      return newState;
    }
    case "RESET_DIRTY": {
      return {
        ...state,
        dirtyFields: []
      };
    }
    case "SHOW_MODAL": {
      return {
        ...state,
        isModalVisible: action.payload
      };
    }
    case "ADD_MEMBER": {
      return {
        ...defaultState,
        dirtyFields: []
      };
    }
    case "DUPLICATE_MEMBER": {
      return {
        ...state,
        identifier: undefined
      };
    }
    case "START_SAVE": {
      return {
        ...state,
        isSubmitting: true
      };
    }
    case "STOP_SAVE": {
      return {
        ...state,
        isSubmitting: false,
        dirtyFields: []
      };
    }
    case "SHOW_DELETE_MODAL": {
      return {
        ...state,
        isDeleteVisible: action.payload
      };
    }
    default:
      return { ...state };
  }
};

export const AccessCodeManager = ({ identifier }) => {
  const [state, componentDispatch] = useReducer(
    memberManagerDispatch,
    Object.assign({}, defaultState, { identifier })
  );
  const { loading, error, data, refetch } = useQuery(GET_ACCESS_CODE, {
    variables: { accessCodeId: state.identifier },
    fetchPolicy: "no-cache"
  });
  const [createAccessCode] = useMutation(CREATE_ACCESS_CODE);
  const [updateAccessCode] = useMutation(UPDATE_ACCESS_CODE);
  const [deleteAccessCode] = useMutation(DELETE_ACCESS_CODES);

  const [, selectDispatch] = useSelectViewModelContext();
  const [, tableDispatch] = useTableViewModelContext();

  useEffect(() => {
    if (state.identifier && data && data.accessCode) {
      const { accessCode } = data;
      componentDispatch({
        type: "SET_ALL",
        payload: Object.assign({ ...state }, { ...accessCode })
      });
    }
  }, [state.identifier, data]);

  const handleChange = useCallback(
    (e) => {
      componentDispatch({
        type: "SET_ONE",
        payload: { name: e.target.name, value: e.target.value }
      });
    },
    [componentDispatch]
  );

  const hideModal = useCallback(() => {
    componentDispatch({
      type: "SHOW_MODAL",
      payload: false
    });
  }, [componentDispatch]);

  const addMember = useCallback(() => {
    componentDispatch({
      type: "ADD_MEMBER"
    });
    toast.success("New access code is ready to be created.", {
      position: "bottom-center",
      hideProgressBar: true,
      pauseOnHover: false,
      closeButton: false
    });
  }, [componentDispatch]);

  // const duplicateMember = useCallback(() => {
  //   componentDispatch({
  //     type: "DUPLICATE_MEMBER"
  //   });
  // }, [componentDispatch]);

  const showDelete = useCallback(() => {
    componentDispatch({
      type: "SHOW_DELETE_MODAL",
      payload: true
    });
  }, [componentDispatch]);

  const hideDelete = useCallback(() => {
    componentDispatch({
      type: "SHOW_DELETE_MODAL",
      payload: false
    });
  }, [componentDispatch]);

  const handleDelete = useCallback(() => {
    if (identifier) {
      const ids = [identifier];
      deleteAccessCode({
        variables: {
          ids: ids
        }
      }).then(
        () => {
          toast.success("Deleted successfully.", {
            position: "bottom-center",
            hideProgressBar: true,
            pauseOnHover: false,
            closeButton: false
          });
          selectDispatch({
            type: "DELETE_IDS",
            payload: { table: "member", ids: [identifier] }
          });
          tableDispatch({
            type: "DELETE_IDS",
            payload: { table: "member", ids: [identifier] }
          });
          addMember();
        },
        (reject) => handleReject(reject, "A problem occurred while deleting this record.")
      );
    }
    hideDelete();
  }, [identifier, hideDelete, deleteAccessCode, selectDispatch, tableDispatch, addMember]);

  const saveMember = useCallback(() => {
    if (state.identifier) {
      const changes = {};
      state.dirtyFields.map((field) => {
        switch (field) {
          default:
            changes[field] = state[field];
            break;
        }
      });
      componentDispatch({ type: "START_SAVE" });
      updateAccessCode({
        variables: {
          changes,
          accessCodeId: state.identifier
        }
      })
        .then(() => {
          refetch();
          toast.success("Updated successfully.", {
            position: "bottom-center",
            hideProgressBar: true,
            pauseOnHover: false,
            closeButton: false
          });
        }, handleReject)
        .finally(() => {
          componentDispatch({ type: "STOP_SAVE" });
        });
    } else {
      componentDispatch({ type: "START_SAVE" });
      createAccessCode({
        variables: {
          code: state.code,
          expiredDate: state.expiredDate
        }
      })
        .then((response) => {
          componentDispatch({
            type: "SET_IDENTIFIER",
            payload: response.data.createAccessCode.accessCodeId
          });
          toast.success("Created successfully.", {
            position: "bottom-center",
            hideProgressBar: true,
            pauseOnHover: false,
            closeButton: false
          });
        }, handleReject)
        .finally(() => {
          componentDispatch({ type: "STOP_SAVE" });
        });
    }
  }, [state, updateAccessCode, refetch, createAccessCode]);

  const saveThenAddMember = useCallback(() => {
    saveMember();
    addMember();
  }, [addMember, saveMember]);

  const handleAddClick = useCallback(() => {
    if (state.dirtyFields.length > 0) {
      componentDispatch({
        type: "SHOW_MODAL",
        payload: true
      });
    } else {
      addMember();
    }
  }, [state.dirtyFields.length, addMember]);

  const handleExpiredDateChange = useCallback((value) => {
    componentDispatch({
      type: "SET_DATE",
      payload: { name: "expiredDate", value }
    });
  }, []);

  if (error) {
    return <div>{error.message}</div>;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    saveMember();
  };
  return (
    <>
      {(loading || state.isSubmitting) && <Loader text={state.isSubmitting && "Saving..."} />}
      <TopBar>
        {/* <LeftButtons>
          <ManagerButton type="button" onClick={handleAddClick}>
            <MdAdd size={iconSize} />
            <ManagerButtonText>New</ManagerButtonText>
          </ManagerButton>
          {// <ManagerButton type="button" onClick={duplicateMember}>
            // <MdContentCopy size={iconSize} />
            // <ManagerButtonText>Duplicate</ManagerButtonText>
          // </ManagerButton>
          }
        </LeftButtons> */}
        <Header>Access Code Manager</Header>
        <RightButtons>
          <ManagerButton type="button" onClick={handleAddClick}>
            <MdAdd size={iconSize} />
            <ManagerButtonText>New</ManagerButtonText>
          </ManagerButton>
          <ManagerButton form="access_code_manager_form" disabled={state.dirtyFields.length === 0}>
            <MdSave size={iconSize} />
            <ManagerButtonText>Save</ManagerButtonText>
          </ManagerButton>
          <ManagerButton disabled={state.identifier === undefined} onClick={showDelete}>
            <MdRemove size={iconSize} />
            <ManagerButtonText>Delete</ManagerButtonText>
          </ManagerButton>
        </RightButtons>
      </TopBar>
      <Form autocomplete="off" id="access_code_manager_form" onSubmit={handleSubmit}>
        <Label required>
          <Text>Access Code</Text>
          <TextInput required name="code" value={state.code} onChange={handleChange} />
        </Label>
        <Label>
          <Text>Expiration Date</Text>
          <StyledDatePicker
            required
            name="expiredDate"
            value={state.expiredDate}
            onChange={handleExpiredDateChange}
          />
        </Label>
      </Form>
      {state.isModalVisible && (
        <ModalWrapper>
          <Modal>
            <ConfirmText>How would you like to continue?</ConfirmText>
            <ConfirmButtonWrapper>
              <Button onClick={saveThenAddMember}>{"Save & Continue"}</Button>
              <Button onClick={addMember}>{"Continue without Saving"}</Button>
              <Button onClick={hideModal}>{"Cancel"}</Button>
            </ConfirmButtonWrapper>
          </Modal>
        </ModalWrapper>
      )}
      {state.isDeleteVisible && (
        <ModalWrapper>
          <Modal>
            <ConfirmText>Are you sure you want to delete this access code?</ConfirmText>
            <ConfirmButtonWrapper>
              <Button onClick={handleDelete}>Delete</Button>
              <Button onClick={hideDelete}>Cancel</Button>
            </ConfirmButtonWrapper>
          </Modal>
        </ModalWrapper>
      )}
      <ToastContainer autoClose={2000} />
    </>
  );
};
